
$offset: 187;
$duration: 3s;

$blue-efc: #1f5e85;

$flat-blue: #07a9e7;
$flat-green: #1abc9c;
$flat-red: #f4726d;
$flat-yellow: #fcd04b;

$flat-grey: #777;
$flat-light-grey: #999;
$flat-lighter-grey: #ccc;
$flat-lightest-grey: #eee;

.loader {
	display: table;
	position: fixed;
    top: 0;
    z-index: 5000;
	right:0;
	left:0;
	bottom:0;
	width: 100%;
    height: 100%;
	background: rgba(255,255,255,0.5);
}

.loader-container {
	h1, h2, h3, h4, h5, h6 {
		color: $flat-light-grey;
	}
}

.loader-inner {
	display:table-row;
	position:relative;
	height:100%;
	width:100%;
}
.loader-spinner-container {
	display:table-cell;
	// display: block;
	position:relative;
	height:100%;
	width:100%;
	text-align: center;
    vertical-align: middle;
}
.loader-spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.loader-path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite,
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
	0% { stroke: $flat-blue; }
	// 25% { stroke: $flat-red; }
	// 50% { stroke: $flat-yellow; }
	// 75% { stroke: $flat-green; }
  100% { stroke: $flat-blue; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}