.formulefinancement-form {

    .form_container {
        padding-bottom: 15px;

        label {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}
