/* --------------------------------

Basic Style

-------------------------------- */

$flat-green: #1abc9c;
$flat-blue: #07a9e7;
$flat-red: #f4726d;
$flat-grey: #777;
$flat-light-grey: #999;
$flat-lighter-grey: #ccc;

// Navigation
#tabs nav {
    padding: 0 0 50px 0;
}

.cd-breadcrumb,
.cd-multi-steps {
    // width: 90%;
    width: 100%;

    // max-width: 768px;
    max-width: 1140px;
    padding: 0.5em 1em;

    // margin: 1em auto;
    margin: 0 auto;
    background-color: #edeff0;
    border-radius: .25em;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    li {
        display: inline-block;
        float: left;
        margin: 0.5em 0;

        .glyphicon {
            vertical-align: -2px;
            margin-right: 5px;
        }

        &:after {
            /* this is the separator between items */
            display: inline-block;
            content: '\00bb';
            margin: 0 .6em;
            color: #959fa5;
        }

        &:last-of-type::after {
            /* hide separator after the last item */
            display: none;
        }

        & > * {
            /* single step */
            display: inline-block;
            font-size: 1.4rem;
            color: #2c3f4c;
        }

        &.current > * {
            color: $flat-blue;
        }
    }

    &.count {
        li {
            counter-increment: steps;

            & > *::before {
                content: counter(steps) " - ";
            }
        }
    }
}

/* --------------------------------

Add a counter to the multi-steps indicator

-------------------------------- */
@media only screen and (min-width: 992px) {
    .cd-multi-steps {
        &.text-top.count,
        &.text-bottom.count {
            li > *::before {
                /* this is the spot indicator */
                content: counter(steps);
                height: 26px;
                width: 26px;
                line-height: 26px;
                font-size: 1.4rem;
                color: #ffffff;
            }

            li:not(.current) em::before {
                /* steps not visited yet - counter color */
                color: #2c3f4c;
            }
        }

        &.text-top.count {
            li::after {
                bottom: 11px;
            }

            li > * {
                padding-bottom: 34px;
            }
        }

        &.text-bottom.count {
            li::after {
                top: 11px;
            }

            li > * {
                padding-top: 34px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .cd-breadcrumb,
    .cd-multi-steps {
        padding: 0 1.2em;

        li {
            margin: 1.2em 0;

            &:after {
                margin: 0 1em;
            }

            & > * {
                font-size: 1.6rem;
            }
        }
    }
}

/* --------------------------------

Multi steps indicator

-------------------------------- */
@media only screen and (min-width: 992px) {
    .cd-multi-steps {
        /* reset style */
        background-color: transparent;
        padding: 0;
        text-align: center;

        li {
            position: relative;
            float: none;
            // margin: 0.4em 40px 0.4em 0;
            margin: 0.4em 60px 0.4em 0;
            background: transparent;

            &:last-of-type {
                margin-right: 0;
            }

            &::after {
                /* this is the line connecting 2 adjacent items */
                position: absolute;
                // content: '';
                content: "\e080";
                // height: 4px;
                // background: #edeff0;
                background: transparent;

                /* reset style */
                margin: 0;
            }

            // &.visited::after {
            //     background-color: $flat-green;
            // }

            & > *,
            &.current > * {
                position: relative;
                // color: #2c3f4c;
                // color: $flat-light-grey;
                color: #fff;
                background-color: $flat-lighter-grey;
                // border: 2px solid $flat-light-grey;
            }
        }

        &.custom-separator li::after {
            /* reset style */
            height: 4px;
            // background: #edeff0;
            background: transparent;
        }

        &.text-center {
            li::after {
                // width: 10px;
                // height: 10px;
                top: 50%;
                // left: 110%;
                left: 112%;
                border-radius: 50%;
                // width: 100%;
                // top: 50%;
                // left: 100%;

                border: none;
                background-color: transparent;
                color: $flat-lighter-grey;
                font-family: 'Glyphicons Halflings';
                font-size: 20px;

                -webkit-transform: translateY(-50%) translateX(-1px);
                -moz-transform: translateY(-50%) translateX(-1px);
                -ms-transform: translateY(-50%) translateX(-1px);
                -o-transform: translateY(-50%) translateX(-1px);
                transform: translateY(-50%) translateX(-1px);
            }

            li > * {
                z-index: 1;
                padding: .6em 1.5em;

                // border-radius: .25em;
                // font-size: 1.15em;
                border-radius: 2em;
                // background-color: #edeff0;
                // background-color: transparent;
                letter-spacing: 1px;
            }

            li.current > * {
                color: #fff;
                background-color: $flat-blue;
                // color: $flat-blue;
                border: 2px solid $flat-blue;
            }

            li.visited > * {
                color: #fff;
                background-color: $flat-blue;
                border: 2px solid $flat-blue;
                opacity: .4;
            }
            li.visited::after {
                color: $flat-blue;
                opacity: .4;
            }

            &.custom-icons {
                li.visited span::before {
                    /* change the custom icon for the visited item - check icon */
                    background-position: 0 -60px;
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #tabs nav {
        ol.cd-multi-steps {
            max-width: 940px;

            &.text-center {
                li::after {
                    display: none;
                }
                li > * {
                    font-size: 16px;
                    letter-spacing: 0;
                }
            }
        }
    }

    .cd-multi-steps li {
        margin: .4em 18px .4em 0;
    }
}

@media only screen and (max-width: 991px) {
    #tabs nav {
        ol.cd-multi-steps {
            max-width: 720px;
        }
    }
}