/* ==========================================================================
   RESPONSIVE
========================================================================== */

//Variables
// Margin
$left-margin: 0px 20px 20px 20px !default;
$right-margin: 0px 20px 0px 20px !default;
$bottom-margin: 15px !default;


// 1200 < Taille
@media (min-width: 1200px) {
    // Espace Candidat
    ._left {
        margin-left: 40px;
    }

    ._right {
        margin-right: 40px;
    }

    // DI -> informations
    ._information-checkbox-civilite {
        margin-left: 10px !important;
    }

    // DI -> validation
    ._validation-col-right {
        margin-left: 5px;
    }
}

// 992 < Taille < 1200 (md)
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    // Header
    .main-header {
        h1 {
            font-size: 28px !important;
        }
    }

    // Espace Candidat
    ._left {
        margin-left: 40px;
    }

    ._right {
        margin-right: 40px;
    }

    // DI -> informations
    ._information-checkbox-civilite {
        margin-left: 10px !important;
    }

    // DI -> financement
    ._financement-formule-padding {
        padding-top: 20px;
    }

    // DI -> validation
    ._validation-col-right {
        margin-left: 5px;
    }

}

// 768 < Taille < 992 (sm)
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    // Header
    .main-header {
        h1 {
            margin-top: 0px !important;
        }
        h3 {
            margin-top: 20px !important;
        }
    }

    // Espace Candidat
    ._left {
        margin-left: 20px;
    }

    ._right {
        margin-right: 20px;
    }

    // DI -> init
    .input-lg {
        margin-bottom: 10px;
    }

    ._panel-connexion {
        margin-left: 30px;
        margin-right: 30px;
    }

    // DI -> informations
    ._panel-padding {
        padding: 18px !important;
    }
    ._panel-padding-modules {
        padding: 27px !important;
    }

    // DI -> financement
    ._financement-mensualite-padding {
        padding-left: 0px !important;
    }
}

// 480 < Taille < 768 (xs)
@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    // Header
    .main-header {
        h1 {
            font-size: 24px !important;
            margin-top: 0px !important;
        }
        h3 {
            font-size: 20px !important;
            margin-top: 20px !important;
        }
        img {
            margin-top: 30px !important;
        }
    }
    // Espace Candidat
    ._left {
        margin: $left-margin;
    }

    ._right {
        margin: $right-margin;
    }

    ._margin-bottom {
        margin-bottom: $bottom-margin;
    }

    ._table-cell{
        display: block !important;
        height: auto!important;
    }
    ._table-row{
        display: inline !important;
    }

    form.action-post-deverouillage,
    .action-aide {
        right: 0px !important;
        top: 25px !important;

        ._btn,
        a {
            width: 90px!important;
            height: 80px!important;
            padding: 10px!important;
            font-size: 13.5px !important;
        }
    }

    // DI -> init
    .input-lg {
        margin-bottom: 10px;
    }

    ._panel-connexion {
        margin-left: 30px;
        margin-right: 30px;
    }

    // DI -> informations
    .form-group-padding {
        padding-left: 0px !important;
    }

    ._date-margin-bottom {
        margin-bottom: 2px;
    }

    // DI -> Formations
    ._formations-left {
        padding-right: 15px !important;

    }
    ._formations-middle {
        padding: 0 15px 0 15px !important;

    }
    ._formations-right {
        padding-left: 15px !important;
    }

}

// 320 < Taille < 480 (xxs)
@media (max-width: 479px) {
    // Header
    .main-header {
        h1 {
            font-size: 20px !important;
            margin-top: 0px !important;
        }
        h3 {
            font-size: 16px !important;
            margin-top: 20px !important;
        }
        img {
            margin-top: 30px !important;
        }
    }

    form.action-post-deverouillage,
    .action-aide {
        right: -10px !important;

        ._btn,
        a {
            width: 70px!important;
            height: 80px!important;
            // padding: 10px!important;
            font-size: 13px !important;
        }
    }

    // Espace Candidat
    ._left {
        margin: $left-margin;
    }

    ._right {
        margin: $right-margin;
    }

    ._margin-bottom {
        margin-bottom: $bottom-margin;
    }

    ._table-cell{
        display: block !important;
        height: auto!important;
    }
    ._table-row{
        display: inline !important;
    }

    // DI -> init
    .input-lg {
        margin-bottom: 10px;
    }

    ._panel-connexion {
        margin-left: 30px;
        margin-right: 30px;
    }

    // DI -> informations
    .form-group-padding {
        padding-left: 0px !important;
    }
    ._date-margin-bottom {
        margin-bottom: 2px;
    }

    // DI -> Formations
    ._formations-left {
        padding-right: 15px !important;

    }
    ._formations-middle {
        padding: 0 15px 0 15px !important;

    }
    ._formations-right {
        padding-left: 15px !important;
    }
}

// 375px < Taille < 420px
@media (min-width: 375px) and (max-width: 460px) {}

// Taille < 375px
@media (max-width: 375px) {}

// 992px < Taille
@media (min-width: $screen-md-min) {}

// Taille < 992px
@media (max-width: $screen-sm-max) {}