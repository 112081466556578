.pietimer-wrapper {
  position:relative;
  width:10px;
  height:10px;
      position: absolute;
    right: 15px;
}

.pietimer-danger {
  background-color: #f2dede;
}
.pietimer-info {
  background-color: #d9edf7;
}
.pietimer-warning {
  background-color: #fcf8e3;
}
.pietimer-success {
  background-color: #dff0d8;
}

@mixin timer($item, $duration, $size, $color, $border, $hover: running) {
  #{$item}, #{$item} * { box-sizing: border-box; }

  #{$item} {
    width: $size;
    height: $size;
  }

  #{$item} .pie {
    width: 50%;
    height: 100%;
    transform-origin: 100% 50%;
    position: absolute;
    background: $color;
    border: #{$border};
  }

  #{$item} .spinner {
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    z-index: 200;
    border-right: none;
    animation: rota $duration + s linear;
  }

  #{$item}:hover .spinner,
  #{$item}:hover .filler,
  #{$item}:hover .mask {
    animation-play-state: $hover;
  }

  #{$item} .filler {
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    left: 50%;
    opacity: 0;
    z-index: 100;
    animation: opa $duration + s steps(1,end) infinite reverse;
    border-left: none;
  }

  #{$item} .mask {
    width: 50%;
    height: 100%;
    position: absolute;
    background: inherit;
    opacity: 1;
    z-index: 300;
    animation: opa $duration + s steps(1,end) infinite;
  }

  @keyframes rota {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes opa {
    0% { opacity: 1; }
    50%, 100% { opacity: 0; }
  }
}

@include timer('.pietimer-wrapper', 6, 14px, '#ddd', '2px solid grey');
